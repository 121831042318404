import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SubLeader from '../../../components/SubLeader/SubLeader'
import WeekendEscapesLauncher from '../../../components/organisms/weekendEscapesLauncher/WeekendEscapesLauncher'
import BlockResults from '../../../components/BlockResults/BlocksResults'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import PageMeta from '../../../components/PageMeta/PageMeta'
import { NationalWeekendEscape } from '../../../types'
import {
  WeekendEscapesResultsQuery,
  WeekendEscapesTextQuery,
  type IWeekendEscapesSearchQueryData,
  type IWeekendEscapesResultsQueryData,
  type IWeekendEscapesTextQueryData
} from '../../../graphql/queries/WeekendEscapesSearchQuery'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      lBBCategory(id: "experiences", idType: SLUG) {
        description
        name
      }
    }
  }
`

interface WeekendEscapesSearchProps {
  data: IWeekendEscapesSearchQueryData
  location: Location
}

const WeekendEscapesSearch = ({
  data: {
    wpgraphql: { adStarCodes, generalSettings }
  },
  location
}: WeekendEscapesSearchProps) => {
  const { siteId, title } = generalSettings
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const params = new URLSearchParams(location.search)
  const county = params.get('county')
  const [searchTerm, setSearchTerm] = useState<string | null>(
    params.get('search') ?? null
  )
  const [posts, setPosts] = useState<
    IWeekendEscapesResultsQueryData | IWeekendEscapesTextQueryData
  >()
  const [postsLoading, setPostsLoading] = useState<boolean>(false)
  const [escapePosts, setEscapePosts] = useState<NationalWeekendEscape[]>()

  const searchQuery = useQuery<IWeekendEscapesTextQueryData>(
    WeekendEscapesTextQuery,
    {
      variables: { search: searchTerm }
    }
  )

  const catQuery = useQuery<IWeekendEscapesResultsQueryData>(
    WeekendEscapesResultsQuery
  )

  useEffect(() => {
    setPosts(searchQuery.data)
    setPostsLoading(searchQuery.loading)
  }, [searchQuery])

  useEffect(() => {
    if (!searchTerm) {
      setPosts(catQuery.data)
      setPostsLoading(catQuery.loading)
    }
  }, [catQuery])

  useEffect(() => {
    if (posts && 'nationalEscapes' in posts) {
      setEscapePosts(posts.nationalEscapes)
    } else if (posts && 'weekendEscapesSearch' in posts) {
      setEscapePosts(posts.weekendEscapesSearch)
    }
  }, [posts])

  // const filterSearch = (results: NationalWeekendEscape[]) => {
  //   if (searchTerm)
  //     return results.filter(escape =>
  //       escape.title
  //         ?.replace(/'/g, '')
  //         .replace(/&#8217;/g, '')
  //         .replace(/\./g, '')
  //         .toLowerCase()
  //         .includes(
  //           searchTerm
  //             .replace(/'/g, '')
  //             .replace(/&#8217;/g, '')
  //             .replace(/\./g, '')
  //             .toLowerCase()
  //         )
  //     )

  //   if (county && !postsLoading)
  //     return results.filter(escape =>
  //       escape.county?.toLowerCase().includes(county.toLowerCase())
  //     )

  //   return results
  // }

  const handleSearchChange = (sTerm: string) => {
    if (sTerm !== searchTerm) {
      if (sTerm.length < 1) {
        navigate('/weekend-escapes/search/')
        setSearchTerm('')
        // setSearchTerm(sTerm)
        // navigate(`/weekend-escapes/search/?search=${sTerm}`)
      } else {
        setSearchTerm(sTerm)
        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        const url = new URL(window.location.href)
        url.searchParams.set('search', sTerm) // Set your parameter and value here
        window.history.pushState({}, '', url)
      }
    }
  }
  
  console.log('county', county)

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: `Weekend Escapes ${
            county ? `in ${county}` : ''
          } | Muddy Stilettos`,
          description:
            'Make your free time count – our carefully-curated guide to the most unique and chic hotels, pubs, bars, restaurants, cafés, cottages and spa breaks across England.',
          image:
            'https://muddystilettos.co.uk/images/weekend-escapes/Leader.jpg',
          url: 'https://muddystilettos.co.uk/weekend-escapes/search'
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner ads={adStarCodes} pageType={`weekendescapes`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <SubLeader>
        <WeekendEscapesLauncher
          currentValue={searchTerm ?? undefined}
          handleSearchChange={handleSearchChange}
        />
      </SubLeader>
      <BlockResults
        category={`Start your search`}
        loading={postsLoading}
        posts={escapePosts ?? []}
        type={`weekend-escapes`}
        {...(county && { county: county })}
      />
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default WeekendEscapesSearch
